import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";

const ImageShow = ({ alt, imgid, fromid, style = {}, type = "" }) => {
  const [image, setImage] = useState(
    `${process.env.REACT_APP_CLAVMALL_IMG}/reseller_images/noimage.jpg`
  );

  useEffect(() => {
    let isSubscribed = true;
    handleCheckImage(isSubscribed);
    return () => (isSubscribed = false);
  }, [imgid]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCheckImage = (isSubscribed) => {
    let image1 = "";

    image1 = `${process.env.REACT_APP_CLAVMALL_IMG}/package_images/package${
      fromid ? fromid : process.env.REACT_APP_ESTORE_DEFAULT_ID
    }/${type}${imgid}`;

    checkIfImageExists(image1, (exist1) => {
      if (exist1 && isSubscribed) {
        return setImage(image1);
      }
    });
  };

  const checkIfImageExists = (url, callback) => {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };

      img.onerror = () => {
        callback(false);
      };
    }
  };

  return (
    image.length && (
      <Carousel
        showArrows={true}
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
      >
        <img src={image} alt={alt} style={style} />
      </Carousel>
    )
  );
};

export default ImageShow;
